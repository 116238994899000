import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import Modal from "react-bootstrap/Modal";


export default function Licenses() {
    const [licenses, setLicenses] = useState([])
    const [schools, setSchools] = useState([])
    const [saveLoading, setSaveLoading] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false)
    const [licenceNumber, setLicenceNumber] = useState('')
    const [schoolId, setSchoolId] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [licensesSelected, setLicensesSelected] = useState([])
    const [date, setDate] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        getLicences()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLicences = async () => {
        await axios.get('https://labs.tinker-dev.com/api/licence/public/listAll')
            .then(response => {
                if (response.data.licences) {
                    setLicenses(response.data.licences.filter((item) => item.schoolID === null || item.schoolID === ''))
                    getSchools()
                }
            })
            .catch(error => {
                toast.error("S-a produs o eroare");
            })
        setLoading(false)

    }

    const getSchools = async () => {
        await axios.post('https://labs.tinker-dev.com/api/school/public/listAll',{
            apiKey: localStorage.getItem('apiKey'),
        }).then(response => {
            if (response.data.schools) {
                setSchools(response.data.schools)
            }
        })
            .catch(error => {
                toast.error("S-a produs o eroare");
            })
        setLoading(false)

    }
    const deleteLicence = async (e,licenceNumber) => {
        e.preventDefault()
        await axios.post('https://labs.tinker-dev.com/api/licence/public/deleteLicence',{
            apiKey: localStorage.getItem('apiKey'),
            licenceNumber: licenceNumber
        }).then(response => {
            setLicenceNumber('')
            getLicences()
            setModalIsOpenDelete(false)
            })
            .catch(error => {
                toast.error("S-a produs o eroare");
            })
        setLoadingDelete(false)

    }
    const assignLicenceToSchool = async (e) => {
        setSaveLoading(true)
        e.preventDefault()
        await licensesSelected.forEach(async (licence) => {
            await axios.post('https://labs.tinker-dev.com/api/licence/public/assignLicenceToSchool',{
                apiKey: localStorage.getItem('apiKey'),
                licenceNumber: licence,
                schoolID: schoolId
            }).then(response => {
            })
                .catch(error => {
                    toast.error("S-a produs o eroare");
                })
        })
        setSchoolId('')
        setLicenceNumber('')
        setLicensesSelected([])
        setSaveLoading(false)
        setModalIsOpen(false)
        getLicences()
        setSaveLoading(false)

    }

    if (loading){
        return (
            <div>
                Se incarca...
            </div>
        )

    }

    return (
        <div className={"license-generator container"}>
            <div className={"row"}>
                <div className={"col-6"}>
                    <h3 style={{textAlign: 'start', marginTop: '38px'}}>Licente neatribuite</h3>
                </div>
                <div className={"col-3"}>
                    <label style={{textAlign: 'start', width: '100%'}} htmlFor="typeField" className="form-label">Status</label>
                    <select id={"typeField"} className={"form-control"} value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value={""}>Toate</option>
                        <option value={"ACTIVE"}>Active</option>
                        <option value={"INACTIVE"}>Inactive</option>
                    </select>
                </div>
                <div className={"col-3"}>
                <label style={{textAlign: 'start', width: '100%'}} htmlFor="typeField" className="form-label">Dată expirare</label>
                    <input
                        type="date"
                        className="form-control"
                        id="startNumber"
                        min={new Date()}
                        value={moment(date).format('YYYY-MM-DD')}

                        onChange={(e) => {
                            setDate(e.target.value)
                        }}
                    />
            </div>
            </div>
            <div className={"page-container licences"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>

                                </th>
                                <th>
                                    Număr licență
                                </th>
                                <th>
                                    Tip
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Folosită de
                                </th>
                                <th>
                                    Identificator pc
                                </th>
                                <th>
                                    Ultima accesare
                                </th>
                                <th>
                                    Dată activare
                                </th>
                                <th>
                                    Dată expirare
                                </th>
                                <th>
                                </th>
                            </tr>
                            </thead>
                            <tbody key={status + date}>
                            {
                                licenses.map((licence) => (
                                    (status === '' || status === licence.status) &&
                                    (date === '' || (licence.expiryDate !== null && moment(date).format('YYYY-MM-DD') === moment(licence.expiryDate).format('YYYY-MM-DD'))) &&
                                    <tr key={licence.licenceNumber}>
                                        <td>
                                          <input type={"checkbox"} checked={licensesSelected.includes(licence.licenceNumber)} onChange={() => {
                                              let temp = [...licensesSelected]
                                              if (temp.includes(licence.licenceNumber)){
                                                  temp.splice(temp.indexOf(licence.licenceNumber),1)
                                              }else{
                                                  temp.push(licence.licenceNumber)
                                              }
                                              setLicensesSelected(temp)

                                          }}/>
                                        </td>
                                        <td>
                                            {licence.licenceNumber}
                                        </td>
                                        <td>
                                            {licence.type}
                                        </td>
                                        <td>
                                            {licence.status}
                                        </td>
                                        <td>
                                            {licence.usedBy}
                                        </td>
                                        <td style={{maxWidth: '100px', textOverflow: "ellipsis", overflow: "hidden"}}>
                                            {licence.pcIdentifier}
                                        </td>

                                        <td>
                                            {
                                                licence.lastActivityDate !== null &&
                                                moment(licence.lastActivityDate).format('DD/MM/YYYY H:mm')}
                                        </td>
                                        <td>
                                            {
                                                licence.redeemedAt !== null &&
                                                moment(licence.redeemedAt).format('DD/MM/YYYY H:mm')}
                                        </td>
                                        <td style={{color: (moment(licence.expiryDate) < moment() ? 'red' : 'black')}}>
                                            {
                                                licence.expiryDate !== null &&
                                                moment(licence.expiryDate).format('DD/MM/YYYY HH:mm')}
                                        </td>
                                        <td style={{minWidth: '80px'}}>

                                            <div className={"d-flex"}>
                                                <div
                                                    style={{cursor: "pointer"}}
                                                    className={"w-100"}
                                                    onClick={() => {
                                                        setModalIsOpen(true)
                                                        setSchoolId(licence.schoolID)
                                                        if (licensesSelected.length === 0){
                                                            let temp = [...licensesSelected]
                                                            temp.push(licence.licenceNumber)
                                                            setLicensesSelected(temp)
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPencilAlt} color={"#0d6efd"}/>
                                                </div>
                                                <div
                                                    style={{cursor: "pointer"}}
                                                    className={"w-100"}
                                                    onClick={() => {
                                                        setLicenceNumber(licence.licenceNumber)
                                                        setModalIsOpenDelete(true)
                                                    }}
                                                >

                                                    <FontAwesomeIcon icon={faTrashCan} color={"#dc3545"}/>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                ) )
                            }
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <Modal show={modalIsOpen} onHide={() => {
                setModalIsOpen(false)
                setLicenceNumber('')
                setSchoolId('')
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Atribuie licențe unei școli</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => assignLicenceToSchool(e)}>
                    <Modal.Body>

                        <label className="form-label mt-3">{
                            licensesSelected.map((licence,i) => <div>{i+1}. {licence}</div>)
                        }</label>
                        <br/>
                        <label htmlFor="licensesCount" className="form-label mt-3">Școala</label>
                        <select id={"typeField"} required className={"form-control"} value={schoolId} onChange={(e) => setSchoolId(e.target.value)}>
                            <option value={""}>Selectează o școală</option>
                            {
                                schools.map((school) => (
                                    <option key={school.id} value={school.id}>{school.name}</option>
                                ))
                            }
                        </select>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type={"submit"}
                            className={"btn btn-primary mt-4 w-100"}
                            disabled={saveLoading}
                        >
                            {
                                saveLoading ?
                                    'Se salvează ...'
                                    :
                                    'Salvează'
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal show={modalIsOpenDelete} onHide={() => {
                setModalIsOpenDelete(false)
                setLicenceNumber('')
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Ștergere licentă: {licenceNumber}</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => deleteLicence(e,licenceNumber)}>
                    <Modal.Body>
                        <label htmlFor="licensesCount" className="form-label mt-3">Sunteți sigur că doriți să ștergeți licența?</label>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type={"submit"}
                            className={"btn btn-primary mt-4 w-100"}
                            disabled={loadingDelete}
                        >
                            {
                                loadingDelete ?
                                    'Se șterge ...'
                                    :
                                    'Șterge'
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    )

}
