import {useState} from "react";
import './LicenseGenerator.css'
import {blobToBase64, pad} from "../../functions";
import axios from "axios";
import {downloadExcel} from 'react-export-table-to-excel';
import {useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify";
import * as XLSX from "xlsx";
import moment from "moment";

export default function LicenseGenerator() {
    let params = useParams();
    const [prefix, setPrefix] = useState('')
    const [expiryDate, setExpiryDate] = useState(new Date())
    const [startNumber, setStartNumber] = useState(0)
    const [licensesCount, setLicensesCount] = useState(1)

    const [type, setType] = useState('GENERAL')
    const [usedBy, setUsedBy] = useState('None')

    const [loading, setLoading] = useState(false)
    const [generateResult, setGenerateResult] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [loadindSendEmail, setLoadindSendEmail] = useState(false)
    const [email, setEmail] = useState('')

    const [showDownloadButton, setShowDownloadButton] = useState(false)


    const getLicensesNumbers = () => {

        let licenses = []

        for (let i = 0; i < licensesCount; i++) {

            if (pad(startNumber + i, 3).length === 3) {
                licenses.push(prefix + pad(startNumber + i, 3))
            }
        }

        return licenses
    }

    const generateLicenses = async () => {
        setLoading(true)
        setShowDownloadButton(false)

        const licenseNumbers = getLicensesNumbers()
        let responseLog = []

        for (let i = 0; i < licenseNumbers.length; i++) {

            await axios.post('https://labs.tinker-dev.com/api/licence/public/createLicence', {
              //  apiKey: 'fmX2tGkxR2KQqENz9Tgc6cxr84EBmbmdQL3T9YeDTjqJwEq8',
                licenceNumber: licenseNumbers[i],
                type: type,
                usedBy: usedBy,
                expiryDate: expiryDate,
                schoolID: params.id
            })
                .then(response => {
                    responseLog.push('Ok')
                })
                .catch(error => {
                    responseLog.push('Licență folosită')
                })
        }

        setGenerateResult(responseLog)
        setShowDownloadButton(true)
        setLoading(false)

    }
    const sendExcelByEmail = async (e) => {

        e.preventDefault()
        setLoadindSendEmail(false)
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

        let arrayExcelFormat = []
        let licencesArray = getLicensesNumbers()
        for(let i=0; i<licencesArray.length; i++) {
            arrayExcelFormat.push({licenta: licencesArray[i]})
        }

        const exportToCSV = (apiData, fileName) => {
            const ws = XLSX.utils.json_to_sheet(apiData);
            const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
            const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
            const data = new Blob([excelBuffer], {type: fileType});
            return data
        }
        const base64File = await blobToBase64(exportToCSV(arrayExcelFormat, 'licente'))

        //Send by email
        await axios.post('https://labs.tinker-dev.com/api/licence/public/sendLicencesByEmail', {
            file: base64File,
            email: email,
            apiKey: localStorage.getItem('apiKey')
        })
            .then(response => {

                setLoadindSendEmail(false)
                setModalIsOpen(false)
                setEmail('')
                toast.success("Email-ul a fost trimis");
            })
            .catch(error => {

                toast.error("S-a produs o eroare");
            })
    }
    const generateExcel = async () => {
        downloadExcel({
            fileName: "licente-skoolvers",
            sheet: "licente",
            tablePayload: {
                header: ['Licență'],
                // accept two different data structures
                body:
                    getLicensesNumbers().map((item) => {
                        return [item]
                    })
            },
        });
    }

    return (
        <div className={"license-generator container"}>
            <button
                type={"button"}
                className={"btn btn-primary"}
                style={{float: 'left'}}
                onClick={()=> window.history.back()}
            >
                Înapoi
            </button>
            <div className={"form"}>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <label htmlFor="prefix" className="form-label">Prefix</label>
                        <input
                            type="text"
                            className="form-control"
                            id="prefix"
                            placeholder={"TNK1234"}
                            maxLength={7}
                            value={prefix}
                            onChange={(e) => setPrefix((e.target.value + '').toUpperCase())}
                        />
                    </div>
                    <div className={"col-6"}>
                        <label htmlFor="startNumber" className="form-label">Număr de pornire</label>
                        <input
                            type="number"
                            className="form-control"
                            id="startNumber"
                            maxLength={3}
                            min={0}
                            max={999}
                            step={1}
                            value={startNumber}
                            onChange={(e) => setStartNumber(parseInt(e.target.value))}
                        />
                    </div>

                    <div className={"col-6"}>
                        <label htmlFor="typeField" className="form-label">Tip</label>
                        <select id={"typeField"} className={"form-control"} value={type} onChange={(e) => setType(e.target.value)}>
                            <option value={"GENERAL"}>General</option>
                            <option value={"CLOUD"}>Cloud</option>
                            <option value={"LOCAL"}>Local</option>
                        </select>
                    </div>
                    <div className={"col-6"}>
                        <label htmlFor="usedByField" className="form-label">Folosită de</label>
                        <select id={"usedByField"} className={"form-control"} value={usedBy} onChange={(e) => setUsedBy(e.target.value)}>
                            <option value={"None"}>-</option>
                            <option value={"Student"}>Student</option>
                            <option value={"Teacher"}>Teacher</option>
                        </select>
                    </div>

                    <div className={"col-6"}>
                        <label htmlFor="expiryDate" className="form-label">Dată expirare	</label>
                        <input
                            type="date"
                            className="form-control"
                            id="expiryDate"
                            min={new Date()}
                            value={moment(expiryDate).format('YYYY-MM-DD')}

                            onChange={(e) => {
                                setExpiryDate(e.target.value)
                            }}
                        />
                    </div>
                    <div className={"col-6"}>
                        <button
                            type={"button"}
                            style={{marginTop: '32px'}}
                            className={"btn btn-primary w-100"}
                            onClick={() => {
                                setExpiryDate(moment(expiryDate).add(1, 'year'))
                            }}
                        > + 1 an
                        </button>
                    </div>
                </div>

                <label htmlFor="licensesCount" className="form-label mt-3">Câte licențe doriți să generați?</label>
                <input
                    type="number"
                    className="form-control"
                    id="licensesCount"
                    maxLength={3}
                    min={1}
                    max={999}
                    step={1}
                    value={licensesCount}
                    onChange={(e) => setLicensesCount(e.target.value)}
                />

                <div className={"mt-4"}>
                    {
                        prefix.length === 7 && licensesCount > 0 ?
                            <>
                                <h4>Urmează să generați următoarele licențe:</h4>

                                <div className={"row"}>
                                    <div className={"col-6"}>
                                        {
                                            getLicensesNumbers().map((license, i) => (
                                                <div key={i}>
                                                    {license}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={"col-6"}>
                                        {
                                            generateResult.map((result, i) => (
                                                <div key={i}>
                                                    {result}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <button
                                    type={"button"}
                                    className={"btn btn-primary mt-4 w-100"}
                                    disabled={loading}
                                    onClick={generateLicenses}
                                >
                                    {
                                        loading ?
                                            'Se generează ...'
                                            :
                                            'Generează licențele'
                                    }
                                </button>

                                {
                                    showDownloadButton ?
                                        <div key={generateResult.length + '_' + getLicensesNumbers().length + '_' + licensesCount}>



                                            <button
                                                    type={"button"}
                                                    className={"btn btn-warning mt-4 w-100"}
                                                    onClick={generateExcel}
                                                >Descarcă .xls
                                                </button>

                                            <button
                                                    type={"button"}
                                                    className={"btn btn-warning mt-4 w-100"}
                                                    onClick={() => {
                                                        setModalIsOpen(true)
                                                        setEmail('')
                                                    }}
                                                >Trimite licențele pe email
                                                </button>

                                        </div>
                                        : null
                                }

                            </>
                            :
                            <>
                                {
                                    prefix.length < 7 ?
                                        <div className={"alert alert-danger"}>Prefixul trebuie să conțină 7
                                            caractere</div>
                                        : null
                                }

                                {
                                    licensesCount <= 0 ?
                                        <div className={"alert alert-danger"}>Numărul de licențe trebuie să fie mai mare
                                            decât 0</div>
                                        : null
                                }

                            </>

                    }
                </div>
            </div>

            <Modal show={modalIsOpen} onHide={() => {
                setModalIsOpen(false)
                setEmail('')
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Trimite licențele pe email</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => {
                    sendExcelByEmail(e)
                }}>
                    <Modal.Body>

                        <label htmlFor="licensesCount" className="form-label mt-3">Email școala</label>
                        <input
                            type="email"
                            className="form-control"
                            minLength={2}
                            maxLength={50}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <div className={"mt-3"}>


                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type={"submit"}
                            className={"btn btn-primary mt-4 w-100"}
                            disabled={loadindSendEmail}
                        >
                            {
                                loadindSendEmail ?
                                    'Se trimite ...'
                                    :
                                    'Trimite'
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    )

}
