import './App.css';
import LicenseGenerator from "./screens/LicenseGenerator/LicenseGenerator";
import Licenses from "./screens/Licenses/Licenses";
import Layout from "./components/Layout/Layout";
import {useState} from "react";
import Login from "./components/Login/Login";
import {useEffect} from "react";

import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Schools from "./screens/Schools/Schools";
import School from "./screens/Schools/School";

function App() {

    const [key, setKey] = useState('')

    useEffect(() => {
        setKey(localStorage.getItem('apiKey'))//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='App'>
            <BrowserRouter>
                <ToastContainer/>
                {
                    key && key !== '' ?
                        <Layout>
                            <Routes>
                                <Route path='/' exact element={<Licenses/>}/>
                                <Route path='/schools' exact element={<Schools/>}/>
                                <Route path='/school/:id' exact element={<School/>}/>
                                <Route path='/generate-license/:id' exact element={<LicenseGenerator/>}/>
                            </Routes>
                        </Layout>
                        :
                        <Routes>
                            <Route path='/' exact element={<Login/>}/>
                        </Routes>
                }
            </BrowserRouter>
        </div>
    );
}

export default App;
