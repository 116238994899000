import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import moment from "moment";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";

export default function School() {
    let params = useParams();

    const [school, setSchool] = useState({})
    const [loading, setLoading] = useState(true)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const [expiryDate, setExpiryDate] = useState(new Date())

    const [saveLoading, setSaveLoading] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [licenceNumber, setLicenceNumber] = useState('')
    const [modalIsOpenDelete, setModalIsOpenDelete] = useState(false)
    const [licensesSelected, setLicensesSelected] = useState([])
    const [date, setDate] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        getSchool()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSchool = async () => {
        await axios.post('https://labs.tinker-dev.com/api/school/public/getSchool', {
            apiKey: localStorage.getItem('apiKey'),
            id: params.id
        }).then(response => {
            if (response.data) {
                setSchool(response.data)
            }
        })
            .catch(error => {
                toast.error("S-a produs o eroare");
            })
        setLoading(false)

    }
    const deleteLicence = async (e, licenceNumber) => {
        e.preventDefault()
        setLoadingDelete(true)
        await axios.post('https://labs.tinker-dev.com/api/licence/public/deleteLicence', {
            apiKey: localStorage.getItem('apiKey'),
            licenceNumber: licenceNumber
        }).then(response => {
            setLicenceNumber('')
            setModalIsOpenDelete(false)
            getSchool()
        })
            .catch(error => {
                toast.error("S-a produs o eroare");
            })
        setLoadingDelete(false)

    }

    const editLicence = async (e) => {
        setSaveLoading(true)
        e.preventDefault()
        await licensesSelected.forEach(async (licence) => {
            await axios.post('https://labs.tinker-dev.com/api/licence/public/updateLicence', {
                apiKey: localStorage.getItem('apiKey'),
                licenceNumber: licence,
                expiryDate: expiryDate
            }).then(response => {
                getSchool();
            })
                .catch(error => {
                    toast.error("S-a produs o eroare");
                })
        })
        setExpiryDate(new Date())
        setSaveLoading(false)
        setModalIsOpen(false)
        setLicensesSelected([])

    }
    if (loading) {
        return (
            <div>
                Se incarca...
            </div>
        )

    }

    return (
        <div className={"license-generator container"}>
            <a
                type={"button"}
                className={"btn btn-primary"}
                style={{float: 'right', marginTop: '32px'}}
                href={"/generate-license/" + params.id}
            >
                Generează licență
            </a>
            <div className={"row"}>
                <div className={"col-6"}>
                    <h3 style={{textAlign: 'start'}}>{school.school.name}</h3>
                </div>
                <div className={"col-3"}>
                    <label style={{textAlign: 'start', width: '100%'}} htmlFor="typeField" className="form-label">Status</label>
                    <select id={"typeField"} className={"form-control"} value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value={""}>Toate</option>
                        <option value={"ACTIVE"}>Active</option>
                        <option value={"INACTIVE"}>Inactive</option>
                    </select>
                </div>
                <div className={"col-3"}>
                    <label style={{textAlign: 'start', width: '100%'}} htmlFor="typeField" className="form-label">Dată expirare</label>
                    <input
                        type="date"
                        className="form-control"
                        id="startNumber"
                        min={new Date()}
                        value={moment(date).format('YYYY-MM-DD')}

                        onChange={(e) => {
                            setDate(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={"page-container"}>
                <div className={"row m-0"}>
                    <div className={"col-12"}>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>
                                    <input type={"checkbox"} checked={licensesSelected.length === school.licences.length} onClick={() => {
                                        if (licensesSelected.length === school.licences.length)
                                            setLicensesSelected([])
                                        else {
                                            let temp = [];
                                            school.licences.forEach((licence) => {
                                                    if ((status === '' || status === licence.status) && (date === '' || (licence.expiryDate !== null && moment(date).format('YYYY-MM-DD') === moment(licence.expiryDate).format('YYYY-MM-DD'))))
                                                        temp.push(licence.licenceNumber);
                                                }
                                            )
                                            setLicensesSelected(temp)

                                        }

                                    }}/>
                                </th>
                                <th>
                                    Număr licență
                                </th>
                                <th>
                                    Tip
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Folosită de
                                </th>
                                <th>
                                    Identificator pc
                                </th>
                                <th>
                                    Ultima accesare
                                </th>
                                <th>
                                    Dată activare
                                </th>
                                <th>
                                    Dată expirare
                                </th>
                                <th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                school.licences && school.licences.map((licence, i) => (
                                    (status === '' || status === licence.status) &&
                                    (date === '' || (licence.expiryDate !== null && moment(date).format('YYYY-MM-DD') === moment(licence.expiryDate).format('YYYY-MM-DD'))) &&
                                    <tr key={i}>
                                        <td>

                                            <input type={"checkbox"} checked={licensesSelected.includes(licence.licenceNumber)} onClick={() => {
                                                let temp = [...licensesSelected]
                                                if (temp.includes(licence.licenceNumber)) {
                                                    temp.splice(temp.indexOf(licence.licenceNumber), 1)
                                                } else {
                                                    temp.push(licence.licenceNumber)
                                                }
                                                setLicensesSelected(temp)

                                            }}/> {i + 1}
                                        </td>
                                        <td>
                                            {licence.licenceNumber}
                                        </td>
                                        <td>
                                            {licence.type}
                                        </td>
                                        <td>
                                            {licence.status}
                                        </td>
                                        <td>
                                            {licence.usedBy}
                                        </td>
                                        <td style={{maxWidth: '100px', textOverflow: "ellipsis", overflow: "hidden"}}>
                                            {licence.pcIdentifier}
                                        </td>

                                        <td>
                                            {
                                                licence.lastActivityDate !== null &&
                                                moment(licence.lastActivityDate).format('DD/MM/YYYY H:mm')}
                                        </td>
                                        <td>
                                            {
                                                licence.redeemedAt !== null &&
                                                moment(licence.redeemedAt).format('DD/MM/YYYY H:mm')}
                                        </td>
                                        <td style={{color: (moment(licence.expiryDate) < moment() ? 'red' : 'black')}}>
                                            {
                                                licence.expiryDate !== null &&
                                                moment(licence.expiryDate).format('DD/MM/YYYY HH:mm')}
                                        </td>
                                        <td>
                                            <div className={"d-flex"}>
                                                <div
                                                    style={{cursor: "pointer"}}
                                                    className={"w-100"}
                                                    onClick={() => {
                                                        setExpiryDate(new Date(licence.expiryDate))
                                                        if (licensesSelected.length === 0) {
                                                            let temp = [...licensesSelected]
                                                            temp.push(licence.licenceNumber)
                                                            setLicensesSelected(temp)
                                                        }
                                                        setModalIsOpen(true)
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPencilAlt} color={"#0d6efd"}/>
                                                </div>
                                                <div
                                                    style={{cursor: "pointer"}}
                                                    className={"w-100 ms-4"}
                                                    onClick={() => {
                                                        setLicenceNumber(licence.licenceNumber)
                                                      //  setExpiryDate(licence.expiryDate)
                                                        setModalIsOpenDelete(true)
                                                    }}
                                                >

                                                    <FontAwesomeIcon icon={faTrashCan} color={"#dc3545"}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <Modal show={modalIsOpen} onHide={() => {
                setModalIsOpen(false)
                setLicensesSelected([])
                setExpiryDate(new Date())
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Editare licențe</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => editLicence(e)}>
                    <Modal.Body>
                        <label className="form-label mt-3">{
                            licensesSelected.map((licence, i) => <div>{i + 1}. {licence}</div>)
                        }</label>
                        <br/>
                        <label htmlFor="expiryDate" className="form-label">Dată expirare</label>
                        <input
                            type="date"
                            className="form-control"
                            id="expiryDate"
                            // min={new Date()}
                            value={moment(expiryDate).format('YYYY-MM-DD')}
                            onChange={(e) => {
                                setExpiryDate(new Date(e.target.value))
                            }}
                        />
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <button
                                    type={"button"}
                                    style={{marginTop: '32px'}}
                                    className={"btn btn-primary w-100"}
                                    onClick={() => {
                                        setExpiryDate(moment(expiryDate).add(1, 'year'))
                                    }}
                                > + 1 an
                                </button>
                            </div>
                            <div className={"col-4"}>
                                <button
                                    type={"button"}
                                    style={{marginTop: '32px'}}
                                    className={"btn btn-primary w-100"}
                                    onClick={() => {
                                        setExpiryDate(moment(expiryDate).add(2, 'year'))
                                    }}
                                > + 2 an
                                </button>
                            </div>
                            <div className={"col-4"}>
                                <button
                                    type={"button"}
                                    style={{marginTop: '32px'}}
                                    className={"btn btn-primary w-100"}
                                    onClick={() => {
                                        setExpiryDate(moment(expiryDate).add(3, 'year'))
                                    }}
                                > + 3 an
                                </button>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type={"submit"}
                            className={"btn btn-primary mt-4 w-100"}
                            disabled={saveLoading}
                        >
                            {
                                saveLoading ?
                                    'Se salvează ...'
                                    :
                                    'Salvează'
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={modalIsOpenDelete} onHide={() => {
                setModalIsOpenDelete(false)
                setLicenceNumber('')
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Ștergere licentă: {licenceNumber}</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => deleteLicence(e, licenceNumber)}>
                    <Modal.Body>
                        <label htmlFor="licensesCount" className="form-label mt-3">Sunteți sigur că doriți să ștergeți licența?</label>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type={"submit"}
                            className={"btn btn-primary mt-4 w-100"}
                            disabled={loadingDelete}
                        >
                            {
                                loadingDelete ?
                                    'Se șterge ...'
                                    :
                                    'Șterge'
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    )

}
