import React, {useState} from 'react';

// style
import './Layout.css'
import {
    NavLink
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const Layout =({children}) =>{

    const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
    return (
        <div className="layout">
            <header id='header' className='container-fluid'>
                <div className='header-container-col'>
                    <div className='row align-items-center'>
                        <div  className='col-12 d-flex justify-content-start'>
                            <NavLink to={"/"}
                                     onClick={() => {
                                        // setSideDrawerOpen(true)
                                     }}>
                                <img src={"/images/logo.svg"} alt={""}/>
                            </NavLink>
                            <NavLink to={"/"}
                                     className="menu-item ms-auto"
                                     onClick={() => {
                                         setSideDrawerOpen(false)
                                     }}
                            >
                                <p className={`font-nunito-bold mb-0`}>
                                    Licente
                                </p>
                            </NavLink>


                            <NavLink to={"/schools"}
                                     className="menu-item ms-4 me-4"
                                     onClick={() => {
                                         setSideDrawerOpen(false)
                                     }}
                            >
                                <p className={`font-nunito-bold mb-0`}>
                                    Școli
                                </p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </header>
            <div className={`side-drawer p-3 ` + (sideDrawerOpen ? 'open' : undefined)}>
                <div className="d-flex flex-column justify-content-center mt-5 navigation-elements">
                    <NavLink to={"/"}
                             className="menu-item"
                             onClick={() => {
                                 setSideDrawerOpen(false)
                             }}
                    >
                        <p className={`font-nunito-bold`}>
                            Licente
                        </p>
                    </NavLink>


                    <NavLink to={"/schools"}
                             className="menu-item"
                             onClick={() => {
                                 setSideDrawerOpen(false)
                             }}
                    >
                        <p className={`font-nunito-bold`}>
                            Școli
                        </p>
                    </NavLink>
                </div>
            </div>
            {
                sideDrawerOpen &&
                <div className="backdrop" onClick={() => setSideDrawerOpen(false)} ></div>
            }
            <div className={"children"}>
                {children}
            </div>
        </div>
    )
    /* }*/
}

export default Layout;
