import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye, faPencilAlt} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";

export default function Schools() {

    const [schools, setSchools] = useState([])
    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [name, setName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [city, setCity] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [schoolId, setSchoolId] = useState('')
    useEffect(() => {
        getSchools()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSchools = async () => {
        await axios.post('https://labs.tinker-dev.com/api/school/public/listAll',{
            apiKey: localStorage.getItem('apiKey'),
        }).then(response => {
                if (response.data.schools) {
                    setSchools(response.data.schools)
                    setLoading(false)
                }
            })
            .catch(error => {
                toast.error("S-a produs o eroare");
            })

    }

    const addSchool = async (e) => {
        e.preventDefault()
        setSaveLoading(true)
        let body = {
            apiKey: localStorage.getItem('apiKey'),
            name
        };
        if (firstName !== '')
            body.firstName = firstName;

        if (lastName !== '')
            body.lastName = lastName;

        if (city !== '')
            body.city = city;

        if (phone !== '')
            body.phone = phone;

        if (email !== '')
            body.email = email;



        await axios.post('https://labs.tinker-dev.com/api/school/public/createSchool', body)
            .then(response => {
                if(response){
                    setName('')
                    setModalIsOpen(false)
                    getSchools()
                }
                setSaveLoading(false)
            })
            .catch(error => {
                toast.error("S-a produs o eroare");
            })

        setSaveLoading(false)

    }
    const editSchool = async (e) => {
        e.preventDefault()
        setSaveLoading(true)
        await axios.post('https://labs.tinker-dev.com/api/school/public/editSchool', {
            apiKey: localStorage.getItem('apiKey'),
            name: name,
            id: schoolId,
            firstName,
            lastName,
            city,
            phone,
            email
        })
            .then(response => {
                if(response){
                    setName('')
                    setSchoolId('')
                    setSaveLoading(false)
                    setModalIsOpen(false)
                    getSchools()
                }
            })
            .catch(error => {
                toast.error("S-a produs o eroare");
            })

        setLoading(false)

    }
/*    const deleteSchool = async (id) => {

        await axios.post('https://labs.tinker-dev.com/api/school/public/deleteSchool',{
            apiKey: localStorage.getItem('apiKey'),
            id: id
        }).then(response => {
            getSchools()
        })
            .catch(error => {
                toast.error("S-a produs o eroare");
            })

    }*/
    if (loading){
        return (
            <div>
                Se incarca...
            </div>
        )

    }

    return (
        <div className={"license-generator container"}>
            <button
                type={"button"}
                className={"btn btn-primary"}
                style={{float: 'right'}}
                onClick={() => setModalIsOpen(true)}
            >
                Adaugă școală
            </button>
            <div className={"row"}>
                <div className={"col-12"}>
                    <div style={{display: "inline-flex", width: '100%'}}>
                        <h3 style={{textAlign: 'start', display: "inline-flex"}}>Școli</h3>

                        <input
                            type="text"
                            style={{display: "inline-flex", width: '200px', marginLeft: "auto"}}
                            className="form-control"
                            minLength={2}
                            maxLength={50}
                            value={search}
                            placeholder={"Caută scoala"}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className={"page-container"}>
                <div className={"row m-0"}>
                    <div className={"col-12"}>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>

                                </th>
                                <th>
                                    Denumire
                                </th>
                                <th>
                                    Persoană de contact
                                </th>
                                <th>
                                    Telefon
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Localitate
                                </th>
                                <th>
                                    Numar licente
                                </th>

                                <th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>{/**/}
                            {
                                schools.map((school,i) => (
                                    school.name.toLowerCase().includes(search.toLowerCase()) &&
                                    <tr key={i}>
                                        <td>
                                            {i + 1}
                                        </td>
                                        <td>
                                            {school.name}
                                        </td>
                                        <td>
                                            {school.firstName} {school.lastName}
                                        </td>
                                        <td>
                                            {school.email}
                                        </td>
                                        <td>
                                            {school.phone}
                                        </td>
                                        <td>
                                            {school.city}
                                        </td>
                                        <td>
                                            {school.licences_count}
                                        </td>
                                        <td>
                                            <div className={"d-flex"} style={{justifyContent: "end"}}>
                                                <div style={{cursor: "pointer"}} onClick={()=> {
                                                    setSchoolId(school.id)
                                                    setName(school.name)
                                                    setFirstName(school.firstName)
                                                    setLastName(school.lastName)
                                                    setEmail(school.email)
                                                    setPhone(school.phone)
                                                    setCity(school.city)
                                                    setModalIsOpen(true)
                                                }}>
                                                    <FontAwesomeIcon icon={faPencilAlt} color={"#0d6efd"}/>
                                                </div>
                                                <a className={"ms-3"} href={'/school/' + school.id}>
                                                    <FontAwesomeIcon icon={faEye} color={"#0d6efd"}/>
                                                </a>
                                          {/*      <div className={"ms-3"} style={{cursor: "pointer"}} onClick={()=> {
                                                    deleteSchool(school.id)
                                                }}>
                                                    <FontAwesomeIcon icon={faTrashCan} color={"red"}/>
                                                </div>*/}
                                            </div>
                                        </td>
                                    </tr>
                                ) )
                            }
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>


            <Modal show={modalIsOpen} onHide={() => {
                setModalIsOpen(false)
                setName('')
                setFirstName('')
                setLastName('')
                setEmail('')
                setPhone('')
                setCity('')
                setSchoolId('')
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{
                        schoolId !== ''?
                            'Editare școală':
                            'Adaugă școala'
                    }</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => {
                    schoolId !== ''?
                        editSchool(e)
                        :
                        addSchool(e)
                }}>
                <Modal.Body>

                        <label htmlFor="licensesCount" className="form-label mt-3">Denumire școala</label>
                        <input
                            type="text"
                            className="form-control"
                            minLength={2}
                            maxLength={50}
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}
                        />


                            <label htmlFor="licensesCount" className="form-label mt-2">Prenume persoană de contact</label>
                            <input
                                type="text"
                                className="form-control"
                                minLength={2}
                                maxLength={50}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            <label htmlFor="licensesCount" className="form-label mt-2">Nume persoană de contact</label>
                            <input
                                type="text"
                                className="form-control"
                                minLength={2}
                                maxLength={50}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            <label htmlFor="licensesCount" className="form-label mt-2">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                minLength={2}
                                maxLength={50}
                                value={email}
                                onChange={(e) =>setEmail(e.target.value)}
                            />
                            <label htmlFor="licensesCount" className="form-label mt-2">Telefon</label>
                            <input
                                type="text"
                                className="form-control"
                                minLength={2}
                                maxLength={50}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <label htmlFor="licensesCount" className="form-label mt-2">Localitate</label>
                            <input
                                type="text"
                                className="form-control"
                                minLength={2}
                                maxLength={50}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />

                    <div className={"mt-3"}>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type={"submit"}
                        className={"btn btn-primary mt-4 w-100"}
                        disabled={saveLoading}
                    >
                        {
                            saveLoading ?
                                'Se salvează ...'
                                :
                                'Salvează'
                        }
                    </button>
                </Modal.Footer>
                </form>
            </Modal>
        </div>
    )

}
