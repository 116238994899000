import {useState} from "react";
import './Login.css'
import axios from "axios";
import {toast} from "react-toastify";

export default function Login() {

    const [password, setPassword] = useState('')

    const checkPassword = async () => {
        if(password) {
            await axios.post('https://labs.tinker-dev.com/api/school/public/listAll',{
                apiKey: password,
            }).then(response => {
                if (response.data.schools) {
                    localStorage.setItem('apiKey', password)
                    window.location.href= '/'
                }
            })
                .catch(error => {
                    toast.error("S-a produs o eroare");
                })

        }
    }

    return (<div className={"container"}>

        <div className={"login-form "}>

            <div className={"text-center"}>
                <img src={'/images/logo.svg'} className={"logo"}  alt={""}/>
            </div>

            <div className="mb-3">
                <label htmlFor="password" className="form-label">Introduceți parola</label>
                <input type="password" className="form-control" id="password"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       placeholder="Introduceți parola" />
            </div>

            <button type={"button"}
                    className={"btn btn-primary w-100"} onClick={checkPassword}>Login</button>
        </div>

    </div>)

}